<template>
  <ion-page class="page" style="margin-top: -40px; background: #41476c">
    <div>
      <div
        class="background-cover"
        :style="`background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.75)), url(${cover})`"
      />
      <div class="social-space-name mx-3 text-white clickable-item" @click="goToPage">
        {{ get(chatroom, 'world.name', '') }}
      </div>

      <ion-badge v-if="chatroomUnReadCounts" color="danger" class="message-badge">
        {{ chatroomUnReadCounts }} New Messages in other chatrooms</ion-badge
      >
    </div>
    <div class="w-100 text-white">
      <div class="d-flex justify-content-between px-3 mt-3">
        <div class="title">Chatroom Information</div>
        <ion-icon class="close-icon clickable-item" :icon="closeOutline" @click="goBack" />
      </div>
      <div class="d-flex flex-column px-3 mt-3">
        <div class="cover" :style="{ 'background-image': `url(${get(chatroom, 'banner_img_url', '/empty.png')})` }" />
        <div class="title mt-2">{{ chatroom.title }}</div>
        <div class="d-flex">
          <div class="mt-1 mb-2">{{ get(chatroomMemberCounts, `${chatroom.id}.member_count`) || 0 }} members</div>
          <ion-button
            v-if="worldstatus.role === 'member'"
            fill="none"
            class="one-button ml-auto"
            @click.stop="dropdownToggle($event, chatroom)"
          >
            <i class="ti-more-alt menu-icon" />
          </ion-button>
        </div>
      </div>
      <div class="chat-room-tags-card text-white px-3 py-3 mt-2">
        <div class="d-flex justify-content-center">
          <ion-badge v-for="(tag, index) of tags" :key="index" color="light ml-2" @click="openTagSearch(tag)"
            >#{{ tag }}</ion-badge
          >
        </div>
        <div class="mt-2">
          <div class="title">Description</div>
          <div class="mt-2 description">{{ truncateText(stripHtmlTags(chatroom.description), 120) }}</div>
        </div>

        <div>
          <div class="title mt-3">Members</div>
          <div>
            <world-char-card-horizontal
              :is-chatroom="true"
              :characters="chatroomMembers"
              :status="worldstatus"
              :privacy="chatroom.privacy"
              @delete="fetchChatroomMembers"
            ></world-char-card-horizontal>
          </div>
        </div>
      </div>
      <div v-if="worldstatus.status !== 'member' && isAuthenticated">
        <div class="sub-title px-3 mt-2">
          To join this chat you have to be a part of "{{ get(chatroom, 'world.name') }}"
        </div>

        <div :class="isMobSmallScreen ? 'py-3' : 'd-flex mt-2'">
          <ion-button v-if="!isMobSmallScreen" class="px-3 social-btn w-100" color="primary" @click="goToSocialSpace"
            >Visit "{{ get(chatroom, 'world.name') }}"</ion-button
          >
          <ion-button
            v-if="get(user, 'is_email_verified', true)"
            class="px-3 social-btn w-100"
            :disabled="isLoading"
            :class="{ hide: joinStatus.status === 'requested' || worldstatus.status === 'requested' }"
            @click="joinSocialSpaceButton"
          >
            <span v-if="joinStatus.status === 'requested' || worldstatus.status === 'requested'">Pending Request</span>
            <div v-else>
              <ChLoading size="sm" v-if="isLoading" class="spinner" />
              <span v-else> Join "{{ get(chatroom, 'world.name') }}"</span>
            </div>
          </ion-button>
          <ion-button
            v-else
            class="px-3 social-btn w-100"
            :disabled="isLoading"
            :class="{ hide: joinStatus.status === 'requested' || worldstatus.status === 'requested' }"
            @click="openVerificationModal"
          >
            <span v-if="joinStatus.status === 'requested' || worldstatus.status === 'requested'">Pending Request</span>
            <div v-else>
              <ChLoading size="sm" v-if="isLoading" class="spinner" />
              <span v-else> Join "{{ get(chatroom, 'world.name') }}"</span>
            </div>
          </ion-button>
        </div>
      </div>
      <div
        v-else-if="
          chatroom.privacy === 'private' && chatroomstatus.status !== 'member' && worldstatus.status === 'member'
        "
        class="text-white"
      >
        <div class="sub-title px-3 mt-3">
          To join a Private Chatroom you have to be a part of {{ get(chatroom, 'title') }}
        </div>

        <div class="d-flex mt-2" :class="isMobSmallScreen ? 'set-bottom-margin' : ''">
          <ion-button
            v-if="get(user, 'is_email_verified', true)"
            class="px-3 social-btn w-100"
            :class="{ hide: joinStatus.status === 'requested' || chatroomstatus.status === 'requested' }"
            @click="joinPrivateChatroom"
          >
            <span v-if="joinStatus.status === 'requested' || chatroomstatus.status === 'requested'"
              >Pending Request</span
            >
            <span v-else> Join {{ get(chatroom, 'title') }} </span></ion-button
          >
          <ion-button
            v-else
            class="px-3 social-btn w-100"
            :class="{ hide: joinStatus.status === 'requested' || chatroomstatus.status === 'requested' }"
            @click="openVerificationModal"
          >
            <span v-if="joinStatus.status === 'requested' || chatroomstatus.status === 'requested'"
              >Pending Request</span
            >
            <span v-else> Join {{ get(chatroom, 'title') }} </span></ion-button
          >
        </div>
      </div>

      <div
        v-else-if="worldstatus.status === 'member'"
        class="d-flex justify-content-center mt-2"
        :class="
          chatroom.privacy === 'private' && isMobSmallScreen
            ? 'flex-column '
            : isMobSmallScreen
            ? 'set-bottom-margin'
            : ''
        "
      >
        <ion-button
          v-if="chatroom.privacy === 'private' && chatroomstatus.status === 'member' && isUser"
          color="danger"
          class="px-3 social-btn w-100"
          @click="leavePrivateChatrooom"
          >Leave Private Chatroom</ion-button
        >

        <ion-button
          class="px-3 social-btn"
          :class="isUser ? 'w-100' : 'w-50'"
          :style="isMuted ? '--background: #328fd2' : '--background: #7050b7'"
          :disabled="isMuteUpdating"
          @click="updateMuteStatus"
        >
          <ion-icon :icon="isMuted ? volumeMediumOutline : volumeMuteOutline" />
          <div class="mx-2">{{ isMuted ? 'Unmute' : 'Mute' }} Chatroom</div>
        </ion-button>
      </div>

      <join-social-space
        :is-open="isFormOpen"
        :modal-title="modalTitle"
        :name="socialspaceAndChatName"
        :is-chatroom="isChatroom"
        :socialspace-id="get(chatroom, 'world.id', '')"
        :chatroom-id="chatroom.id"
        @dismiss-modal="closeForm"
        @status="checkStatus"
      />
      <VerificationModal :is-open="verificationModal" @dismiss-modal="closeVerificationModal" />
    </div>
  </ion-page>
</template>

<script lang="ts" setup>
import { closeOutline } from 'ionicons/icons';
import { alertController } from '@ionic/vue';
import JoinSocialSpace from '@/shared/modals/JoinSocialSpace.vue';
import WorldCharCardHorizontal from '@/shared/components/WorldCharCardHorizontal.vue';
import {
  chatroomUnreadMessageCounts,
  getchatroomDetails,
  getChatroomMemberCounts,
  getChatroomMembers,
  getUnmuteAndMuteStatus,
  leavesocialSpaceChatroom,
  mutesocialSpaceChatroom,
} from '@/shared/actions/socialSpaceChatroom';
import { truncateText } from '@/shared/utils/string';
import { stripHtmlTags } from '@/shared/utils/string';
import { createWorldCharUserRequest, getMemberstatus } from '@/shared/actions/worlds';
import { toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';

import { popovers } from '@/shared/native/popovers';
import ChatroomPopover from '../popovers/ChatroomPopover.vue';
import VerificationModal from '@/shared/modals/VerificationModal.vue';

const { user } = authStore();

const router = useRouter();
const chatroom = ref({}) as any;
const chatroomMembers = ref([]) as any;
const chatroomMemberid = ref([]) as any;
const chatroomMemberCounts = ref({});
const worldstatus = ref({}) as any;
const chatroomstatus = ref({}) as any;
const isFormOpen = ref(false);
const isChatroom = ref(false);
const joinStatus = ref({}) as any;

const chatroomUnReadCounts = ref(0);
const route = useRoute();
const isLoading = ref(false);
const isMuted = ref(false);
const isMuteUpdating = ref(false);
const { user: loggedInUser, isAuthenticated } = authStore();

const openTagSearch = (tagText: string) => {
  const hashText = '#';
  tagText = hashText.concat(tagText);
  router.push({ name: 'search', query: { term: tagText, tab: 'all' } });
};

const verificationModal = ref(false);

const openVerificationModal = () => {
  verificationModal.value = true;
};

const closeVerificationModal = () => {
  verificationModal.value = false;
};

const isUser = computed(() => {
  return get(chatroom.value.world, 'user.username', '') !== loggedInUser.value.username;
});

const name = computed(() => {
  return 'my chatroom';
});

const username = computed(() => {
  return 'username';
});

const modalTitle = computed(() => {
  return isChatroom.value
    ? 'Join ' + get(chatroom.value, 'title', '')
    : 'Join ' + get(chatroom.value, 'world.name', '');
});

const tags = computed(() => {
  return chatroom.value.tags;
});

const fetchSocialSpaceStatus = async () => {
  const resp = await getMemberstatus({ world_id: get(chatroom.value, 'world.id', '') });
  worldstatus.value = resp;
};
const fetchSocialSpaceChatroomStatus = async () => {
  const resp = await getMemberstatus({
    world_id: get(chatroom.value, 'world.id', ''),
    room_id: get(chatroom.value, 'id', ''),
  });
  chatroomstatus.value = resp;
  if (chatroomstatus.value.status === 'member') {
    fetchMuteAndUnmuteStatus();
  }
};

const dropdownToggle = async (ev: CustomEvent, chatroom: any) => {
  // isDropDownToggle.value = true;
  const popover = await popovers.open(ev, ChatroomPopover, { chatroom: chatroom, status: worldstatus });
  try {
    const { data } = await popover.onDidDismiss();
  } catch (e) {}
};

const fetchChatroomMessageCounts = async () => {
  const resp = await chatroomUnreadMessageCounts(chatroom.value.id);
  chatroomUnReadCounts.value = resp.unread_count;
};
const goToPage = () => {
  router.push({ name: 'social-space-details', params: { slug: get(chatroom.value, 'world.slug') } });
};

const cover = computed(() => {
  return get(chatroom.value, 'world.cover_image', '/empty.png');
});

const updateMuteStatus = async () => {
  if (isMuteUpdating.value) return;

  isMuteUpdating.value = true;
  const resp = await mutesocialSpaceChatroom(chatroom.value.id, !isMuted.value);
  isMuted.value = !isMuted.value;

  isMuteUpdating.value = false;
};

const leavePrivateChatrooom = async () => {
  try {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Are you sure?',
      message: `Please confirm that you want to leave this chatroom. You will no longer be able to interact with this chat.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-secondary',
          id: 'cancel-button',
        },
        {
          text: 'Confirm',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-danger',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'ok') {
      await leavesocialSpaceChatroom(chatroom.value.id);
      toast.show('Left Chatroom successfully', 'nonative', 'success');
      router.push({ name: 'social-space' });
    }
  } catch (e) {
    toast.show('Encountered an error - please try again later.', 'nonative', 'danger');
  }
};
const goBack = () => {
  if (chatroomstatus.value.status === 'member') {
    router.go(-1);
  } else {
    router.push({ name: 'social-space-details', params: { slug: get(chatroom.value, 'world.slug') } });
  }
};

const goToSocialSpace = () => {
  router.push({ name: 'social-space-details', params: { slug: chatroom.value.world.slug } });
};

const fetchChatroomMemberCounts = async () => {
  chatroomMemberCounts.value = {};
  chatroomMemberid?.value.push(chatroom.value.id);
  const resp = await getChatroomMemberCounts(get(chatroom.value, 'world.id'), chatroomMemberid.value);
  chatroomMemberCounts.value = keyBy(resp, 'room');
};
const fetchChatroomMembers = async () => {
  const resp = await getChatroomMembers(chatroom.value.id, 1);
  chatroomMembers.value = resp.results;
};
const joinSocialSpaceButton = async () => {
  if (get(chatroom.value, 'world.privacy') === 'O') {
    isLoading.value = true;

    await createWorldCharUserRequest({
      requestee_id: loggedInUser.value.id,
      requestee_type: 'user',
      world: get(chatroom.value, 'world.id'),
      message: '',
    });

    fetchSocialSpaceStatus();
    isLoading.value = false;
  } else {
    joinStatus.value.status === 'requested' || worldstatus.status === 'requested' ? '' : (isFormOpen.value = true);
  }
};
const socialspaceAndChatName = computed(() => {
  return isChatroom.value ? get(chatroom.value, 'title', '') : get(chatroom.value, 'world.name', '');
});

const fetchMuteAndUnmuteStatus = async () => {
  const resp = await getUnmuteAndMuteStatus(chatroom.value.id);
  isMuted.value = resp.status;
};
const getRoomDetail = async () => {
  const resp = await getchatroomDetails(route.params.id as string);
  chatroom.value = resp;
  fetchChatroomMembers();
  fetchChatroomMemberCounts();
  fetchSocialSpaceStatus();
  fetchSocialSpaceChatroomStatus();
  fetchChatroomMessageCounts();
};
const joinPrivateChatroom = async () => {
  if (joinStatus.value.status !== 'requested' || chatroomstatus.value.status !== 'requested') {
    isFormOpen.value = true;
    isChatroom.value = true;
  }
};

const checkStatus = (status: string) => {
  joinStatus.value = status;
};
const closeForm = () => {
  isFormOpen.value = false;
  if (isChatroom.value === true) isChatroom.value = false;
};
onMounted(() => {
  getRoomDetail();
  window.addEventListener('resize', handleResize);
});

const { width: windowWidth } = useWindowSize();

const isMobSmallScreen = computed(() => {
  return windowWidth.value <= 500;
});
const handleResize = () => {
  windowWidth.value = window.innerWidth;
};
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style lang="sass" scoped>
.one-button
  min-width: 20px
  height: 18px
  font-size: 12px
  ::v-deep
    --background: #989aa2 !important

.spinner
  width: 15px
  height: 15px
.set-bottom-margin
  margin-bottom: 15px

.hide
  pointer-events: none
.chat-room-tags-card
  overflow: unset
  border-radius: 0
  background: #626680
  @media(min-width: 420px)
    min-width: 100% !important
    max-width: 28% !important
  @media(max-width: 419px)
    min-width: 100% !important
    max-width: 50% !important
.sub-title
  font-size: 18px
  font-weight: bold
.social-space-name
  margin-top: -33px
  position: absolute
  font-size: 20px
  font-weight: bold
.message-badge
  position: absolute
  right: 14px
  top: 14px
  border-radius: 10px
.background-cover
  height: 100px
  width: 100%
  background-position: center
  background-size: cover
  background-repeat: no-repeat
  background-blend-mode: lighten
  opacity: 0.5
.close-icon
  font-size: 25px
.social-btn
  height: 37px
  width: 100%
  @media(max-width: 500px)
    text-transform: uppercase
    font-weight: 900
::v-deep
  .button
    --overflow: unset !important
    --background: #00b4c5
    --border-radius: 10px

.mute-btn
  height: 37px
  width: calc(100% - 395px)
  @media(max-width: 500px)
    text-transform: uppercase
    font-weight: 900
::v-deep
  .button
    --overflow: unset !important
    --background: #00b4c5
    --border-radius: 10px

.title
  font-size: 20px
  font-weight: bold
.cover
  height: 250px
  width: 100%
  background-position: center
  background-size: cover
  background-repeat: no-repeat
  border-radius: 15px
  object-fit: cover

.description
  font-size: 16px
</style>
